

















import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import GlobalFooter from '@/components/global/footer.vue'
import GlobalRobotList from '@/components/global/robot-list.vue'
import GlobalTab from '@/components/global/tab.vue'
import MomentItem from '@/components/moment/item.vue'
import {
  api
} from "@/api"
import {
  WechatModule
} from "@/store/modules/wechat"
import {
  onScroll
} from '@/utils/func'

@Component({
  name: 'moment',
  components: {
    GlobalFooter,
    GlobalRobotList,
    GlobalTab,
    MomentItem
  }
})
export default class Moment extends Vue {
  private tabList: any = [{
    label: '设备朋友圈',
    value: 0
  }, {
    label: '全部朋友圈',
    value: 1
  }]
  private pageNo = 1
  private pageSize = 10
  private hasNextPage = true
  private list: any = []
  private tabVal: number = 0
  private isRefresh = false
  private isRequest = false

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  @Watch('wxId', {
    immediate: true,
    deep: true
  })
  private onChangeWxStrId(val: any) {
    if (val) {
      this.initMoment()
    }
  }

  /**
   * @func 初始化朋友圈请求
   */
  private initMoment(e ? : any) {
    this.pageNo = 1
    this.hasNextPage = true
    this.list = []
    this.isRequest = false

    if (!this.tabVal) {
      this.getDeviceMoment(e)
    } else {
      this.getAllMoment(e)
    }
  }

  /**
   * @func 修改tab回调
   */
  private changeTab(val: number) {
    this.tabVal = val
    this.initMoment()
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.list.length > 0) {
          this.tabVal ? this.getAllMoment() : this.getDeviceMoment()
        }
      }
    })
  }

  /**
   * @func 获取设备朋友圈
   */
  private async getDeviceMoment(obj ? : any) {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    let wxid = obj ? obj.wxid : this.wxId,
      username = obj ? obj.wx_str_id : this.wxStrId
    try {
      const res: any = await api.getDeviceMoments({
        offset: (this.pageNo - 1) * this.pageSize,
        num: this.pageSize,
        wechat_id: wxid,
        username: username
      });
      !res.list || res.list.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++;
      if (res.list) {
        res.list.map((val: any) => {
          let sns_like_and_comment = val.like_and_comment && JSON.parse(val.like_and_comment);
          val.like = sns_like_and_comment ? sns_like_and_comment.likeUserList : [];
          val.comment = sns_like_and_comment ? sns_like_and_comment.commentUserList : [];
          val.images = val.images && JSON.parse(val.images);
          val.pics = []
          if (val.images) {
            for (let i = 0; i < val.images.length; i++) {
              val.pics.push(val.images[i].imageUrl)
            }
          }
          val.media = val.media && JSON.parse(val.media);
          val.wechat_id = this.wxId;
          this.list.push(val);
        });
      }
      this.isRequest = false
    } catch (err) {
      console.log(err)
      this.isRequest = false
    }
  }

  /**
   * @func 获取全部朋友圈
   */
  private async getAllMoment(obj ? : any) {
    if (this.isRequest) return;
    if (!this.hasNextPage) return
    this.isRequest = true
    let wxid = obj ? obj.wxid : this.wxId
    try {
      const res: any = await api.getAllMoments({
        wechat_id: wxid,
        offset: (this.pageNo - 1) * this.pageSize,
        num: this.pageSize
      });
      !res.list || res.list.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++
      if (res.list) {
        res.list.map((val: any) => {
          let sns_like_and_comment = val.like_and_comment && JSON.parse(val.like_and_comment);
          val.like = sns_like_and_comment ? sns_like_and_comment.likeUserList : [];
          val.comment = sns_like_and_comment ? sns_like_and_comment.commentUserList : [];
          val.images = val.images && JSON.parse(val.images);
          val.pics = []
          if (val.images) {
            for (let i = 0; i < val.images.length; i++) {
              val.pics.push(val.images[i].imageUrl)
            }
          }
          val.media = val.media && JSON.parse(val.media);
          val.wechat_id = this.wxId;
          this.list.push(val);
        })
      }
      this.isRequest = false
    } catch (err) {
      console.log(err)
      this.isRequest = false
    }
  }

  /**
   * @func 刷新朋友圈
   */
  private doRefresh() {
    if (this.isRefresh) return
    this.$weui.confirm('不能频繁操作,间隔20分钟进行刷新', () => {
      this.refreshReq()
    }, () => {
      console.log('cancel')
    })
  }

  /**
   * @func 执行刷新操作
   */
  private async refreshReq() {
    this.isRefresh = true
    try {
      const res:any = await api.refreshTimeline({
        wechat_id: this.wxId,
        refresh_type: 0,
        username: this.wxStrId
      })
      this.$weui.topTips('刷新成功，30秒后查看', {
        className: 'success-tips'
      })
      this.isRefresh = false
    } catch(err) {
      console.log(err)
    }
  }

  /**
   * @func 创建朋友圈
   */
  private toCreate() {
    this.$router.push({
      name: 'CreateMoment'
    })
  }
}
